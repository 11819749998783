import Vue from "vue";
import store from "@/store";
import router from "@/router";
import axios from "axios";
import VueAxios from "vue-axios";
import { globalLoader } from "@/helpers/variables";

const camelcaseObjectDeep = require("camelcase-object-deep");

Vue.use(VueAxios, axios);

let isTokenRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

function getUserAuthToken() {
    return localStorage.getItem("user_token");
}

export const $http = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    headers: {
        Authorization: `Bearer ${getUserAuthToken()}`,
        Accept: "application/json",
        "Access-Control-Allow-Headers": "*",
    },
});

$http.interceptors.request.use(config => {
    const token = getUserAuthToken();
    if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers["X-localization"] = store.getters.currentLanguage.translationKey;

    return config;
});

async function tryToRefreshToken(originalRequest) {
    if (isTokenRefreshing) {
        try {
            let token = await new Promise((resolve, reject) => {
                failedQueue.push({ resolve, reject });
            });
            originalRequest.headers.Authorization = `Bearer ${token}`;

            const response = await Vue.axios(originalRequest);
            response.data = camelcaseObjectDeep(response.data);

            return response;
        } catch (error) {
            return error;
        }
    }

    originalRequest.retry = true;
    isTokenRefreshing = true;

    return new Promise((resolve, reject) => {
        $http
            .post("v1/auth/refresh")
            .then(async response => {
                const accessToken = response.data.data.accessToken;
                store.commit(`auth/SET_TOKEN`, accessToken);

                originalRequest.headers.Authorization = `Bearer ${accessToken}`;

                processQueue(null, accessToken);

                const originalResponse = await Vue.axios(originalRequest);
                originalResponse.data = camelcaseObjectDeep(originalResponse.data);

                resolve(originalResponse);
            })
            .catch(error => {
                console.log(error);

                router.push({ name: "main" }).catch(() => {});

                store.commit(`auth/REMOVE_TOKEN`);

                processQueue(error, null);

                reject(error);
            })
            .then(() => {
                isTokenRefreshing = false;
            });
    });
}

$http.interceptors.response.use(
    response => {
        response.data = camelcaseObjectDeep(response.data);

        if (response.data.adminData) {
            store.commit("profile/setAdminData", response.data.adminData);
        }

        return response;
    },
    /**
     * @param {AxiosError|any} error
     */ error => {
        const originalRequest = error.config;

        if (error.config.hasOwnProperty("errorHandle") && error.config.errorHandle === false) {
            return Promise.reject(camelcaseObjectDeep(error.response));
        }

        switch (error.response.status) {
            case 503: {
                router.push({ name: "server-maintenance" }).catch(() => {});
                globalLoader(false);
                break;
            }
            case 500: {
                router.push({ name: "server-error" }).catch(() => {});
                globalLoader(false);
                break;
            }
            case 404: {
                router.push({ name: "page-not-found" }).catch(() => {});
                break;
            }
            case 403: {
                Vue.toasted.error("Доступ до сторінки чи ресурсу заборонено");

                break;
            }
            case 401: {
                // router.push({name: 'sing-in'}).catch(() => {
                //   console.log()
                //
                // });

                if (!originalRequest.retry) {
                    // if it is a first failed request, then we try to refresh the token
                    return tryToRefreshToken(originalRequest);
                }

                store.commit("auth/REMOVE_TOKEN");
                store.commit("popups/CHANGE_LOGIN_POPUP", true);

                break;
            }
            default: {
                break;
            }
        }

        return Promise.reject(camelcaseObjectDeep(error.response));
    }
);

export const $httpInstagram = axios.create({
    baseURL: "https://api.instagram.com/oauth/",
});

export default function install(Vue) {
    Object.defineProperty(Vue.prototype, "$http", {
        get() {
            return $http;
        },
    });
}
