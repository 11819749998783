// import mainInput from '../../atoms/main-input/index.vue'
import { mapActions, mapGetters, mapMutations } from "vuex";

import { email, maxLength, minLength, required } from "vuelidate/lib/validators";
import validation from "@/mixins/validation";

export default {
    name: "login-manager",
    data() {
        return {
            payload: {
                email: this.$route.query.email ? this.$route.query.email : "",
                password: "",
                as_manager: 1
            },
            validationErrors: {},
            showPassword: false,
            emailRules: {
                require: true,
                email: true,
            },
            passwordRules: {
                minLength: 8,
                require: true,
            },
            inputRefs: [],
        };
    },
    mixins: [validation],
    validations: {
        payload: {
            email: {
                required,
                maxLength: maxLength(120),
                email,
            },
            password: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(120),
            },
        },
    },
    components: {},
    computed: {
        ...mapGetters({
            loadingSingIn: "auth/loadingSingIn",
        }),
        passwordErrors() {
            return this.computeErrors('payload', 'password', {
                maxLength: {message: "validationMax", count: 120 },
                required: "validationRequired",
                minLength: {message: "validationMin", count: 8},
            });
        },
        emailErrors() {
            return this.computeErrors('payload', 'email', {
                maxLength: {message: "validationMax", count: 120 },
                required: "validationRequired",
                email: "validationEmail",
            });
        },
    },
    created() {
        document.getElementById("html").classList.add("hide");
    },
    methods: {
        ...mapMutations({
            changeRegistrationPopup: "popups/CHANGE_REGISTRATION_POPUP",
            changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
            changeResetPopup: "popups/CHANGE_RESET_POPUP",
            changeRegisterAgentPopup: "auth/SHOW_AGENT_REGISTER_POPUP",
            changeLoginManagerPopup: "popups/CHANGE_LOGIN_MANAGER_POPUP",
        }),
        ...mapActions({
            login: "auth/GET_TOKEN",
            fetchUser: "profile/FETCH_USER_DATA",
            getFavorites: "favorites/GET_FAVORITES_LIST",
            fetchHeaderMenu: "setting/GET_HEADER_MENU",
        }),
        setItemRef(el) {
            if (el) {
                this.inputRefs.push(el);
            }
        },
        async submit() {
            this.$v.$touch();
            if (this.$v.$invalid) return;

            try {
                await this.login(this.payload).then(() => {
                    this.fetchUser().then(() => this.fetchHeaderMenu());
                    this.getFavorites();
                });

                this.$toasted.success(this.$t("successLogin"));
                this.changeRegistrationPopup(false);
                this.changeLoginPopup(false);
                this.changeLoginManagerPopup(false);
                this.$router.nav;
            } catch (error) {
                const message = error.data?.message;
                if (message) {
                    this.$toasted.error(message);
                }
            }
        },
        changePopup() {
            this.changeRegistrationPopup(true);
            this.changeLoginPopup(false);
        },
    },
};
