import {$http} from '@/utils/https'
import type {ActionContext} from "vuex";
import type {RootState} from "@/store";

interface State {
  promotionsProducts: any,
  promotionsLoading: boolean,
}

const state: State = {
  promotionsProducts: null,
  promotionsLoading: false,
};

const getters = {
  promotionsProducts: (state: State) => state.promotionsProducts,
  promotionsLoading: (state: State) => state.promotionsLoading,
};

const actions = {
  FETCH_PROMOTIONS_PRODUCTS: async ({ commit }: ActionContext<State, RootState>, params: any) => {
    try {
      // commit('SET_PROMOTIONS_LOADING', true);
      const response = await $http.get('v1/sales', { params });
      commit('SET_PROMOTIONS_PRODUCTS', response.data);
      return response.data;
    } catch (e) {
      throw e;
    } finally {
      // commit('SET_PROMOTIONS_LOADING', false);
    }
  },
};



const mutations = {
  SET_PROMOTIONS_PRODUCTS(state: State, products: any) {
    state.promotionsProducts = products;
  },
  SET_PROMOTIONS_LOADING(state: State, isLoading: boolean) {
    state.promotionsLoading = isLoading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
