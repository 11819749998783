import {$http} from '@/utils/https'
import type {ActionContext} from "vuex";
import type {RootState} from "@/store";
import type {PaginatorData} from "@/types/api";
import type {DocFileInfo, DocType, DocumentData} from "@/types/documents";

const API_ENDPOINT = 'v1/documents';

interface State {
  documents: Array<DocumentData>;
  documentInfo: DocumentData | {};
  perPage: number;
  page: number;
  pdf: DocFileInfo | {};
  haveNextPage: boolean;
  loading: boolean;
}

const state: State = {
  documents: [],
  documentInfo: {},
  perPage: 10,
  page: 1,
  pdf: {},
  haveNextPage: false,
  loading: false,
};

const getters = {
  documents: (state: State) => state.documents,
  haveNextPage: (state: State) => state.haveNextPage,
  documentInfo: (state: State) => state.documentInfo,
  loading: (state: State) => state.loading,
  pdf: (state: State) => state.pdf,
};

const actions = {
  GET_DOCUMENTS: async ({commit, state}: ActionContext<State, RootState>, payload: { type: DocType }) => {
    state.loading = true;
    state.page = 1;
    try {
      commit('SET_DOCUMENTS', {});
      const responseDoc = await $http.get<PaginatorData<Array<DocumentData>>>(
        API_ENDPOINT,
        {params: {type: payload.type, page: state.page, perPage: state.perPage}}
      );
      state.haveNextPage = responseDoc.data.links.next !== null;
      commit('SET_DOCUMENTS', responseDoc.data.data);
    } catch (e) {
      throw e;
    } finally {
      state.loading = false;
    }
  },
  GET_DOCUMENTS_NEXT_PAGE: async ({commit, state}: ActionContext<State, RootState>, payload: { type: DocType }) => {
    state.loading = true;
    try {
      state.page++;
      const responseDoc = await $http.get<PaginatorData<Array<DocumentData>>>(
        API_ENDPOINT,
        {params: {type: payload.type, page: state.page, perPage: state.perPage}}
      );
      state.haveNextPage = responseDoc.data.links.next !== null;
      commit('ADD_DOCUMENTS', responseDoc.data.data);
    } catch (e) {
      throw e;
    } finally {
      state.loading = false;
    }
  },
  GET_DOCUMENT_INFO: async ({commit, state}: ActionContext<State, RootState>, payload: { id: string }) => {
    state.loading = true;
    try {
      commit('SET_DOCUMENT_INFO', {});
      const responseDoc = await $http.get<{ data: DocumentData }>(`${API_ENDPOINT}/${payload.id}`);
      commit('SET_DOCUMENT_INFO', responseDoc.data.data);
    } catch (e) {
      throw e;
    } finally {
      state.loading = false;
    }
  },
  GET_DOCUMENT_PDf: async ({commit, state}: ActionContext<State, RootState>, payload: { id: string }) => {
    state.loading = true;
    try {
      commit('SET_DOCUMENT_PDF', {});
      const responseDoc = await $http.get<{ data: Array<any> }>(`${API_ENDPOINT}/${payload.id}/pdf`);
      commit('SET_DOCUMENT_PDF', responseDoc.data.data);
      return responseDoc.data;
    } catch (e) {
      throw e;
    } finally {
      state.loading = false;
    }
  },
  GET_DOCUMENT_EXCEL: async ({commit, state}: ActionContext<State, RootState>, payload: { id: string }) => {
    state.loading = true;
    try {
      commit('SET_DOCUMENT_PDF', {});
      const responseDoc = await $http.get<{ data: Array<any> }>(`${API_ENDPOINT}/${payload.id}/excel`);
      commit('SET_DOCUMENT_PDF', responseDoc.data.data);
      return responseDoc.data;
    } catch (e) {
      throw e;
    } finally {
      state.loading = false;
    }
  },
}

const mutations = {
  SET_DOCUMENTS(state: State, documents: Array<DocumentData>) {
    state.documents = documents;
  },
  ADD_DOCUMENTS(state: State, documents: Array<DocumentData>) {
    state.documents = state.documents.concat(documents);
  },
  SET_DOCUMENT_INFO(state: State, document: DocumentData) {
    state.documentInfo = document;
  },
  SET_DOCUMENT_PDF(state: State, pdf: DocFileInfo) {
    state.pdf = pdf;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
