import _ from "lodash";

export const NavItemType = {
    BrandItem: "brand_item",
    BrandSeriesItem: "brand_series_item",
    CatalogItem: "catalog",
    MenuItem: "menu_item",
    PageItem: "page_item",
    ProductItem: "product",
    SinglePageMenuItem: "single_page_menu_item",
    ProductDiscountsItem: "product_discounts_item",
};

const availableMenuItemTypes = [
    NavItemType.BrandItem,
    NavItemType.BrandSeriesItem,
    NavItemType.CatalogItem,
    NavItemType.MenuItem,
    NavItemType.PageItem,
    NavItemType.ProductItem,
    NavItemType.SinglePageMenuItem,
    NavItemType.ProductDiscountsItem,
];

/**
 * @param {NavigationItem} navItem
 * @return {boolean}
 */
export function isProcessableItem({ itemType }) {
    return _.includes(availableMenuItemTypes, itemType);
}

/**
 * @param {NavigationItem} navItem
 * @return {boolean}
 */
export function isClickableInMainMenu(navItem) {
    return navItem.itemType === NavItemType.SinglePageMenuItem || navItem.isLink;
}

/**
 * @param {NavigationItem} navItem
 * @return {boolean}
 */
export function isClickableInSidebarMenu(navItem) {
    return navItem.itemType === NavItemType.SinglePageMenuItem;
}

/**
 * @param {NavigationItem} navItem
 * @param {NavigationItem|null} parentNavItem
 * @return {Object}
 */
export function routeForMenuItem(navItem, parentNavItem = null) {
    const { itemType, slug } = navItem;

    if (parentNavItem) {
        if (slug === "seminars" && parentNavItem.slug === "education") {
            return { name: "training" };
        }

        if (parentNavItem.slug === "products") {
            if (slug === "catalog-discounts") {
                return {
                    name: "products",
                    query: { sort_filter_type: "with_promotions" },
                };
            }

            if (itemType === NavItemType.BrandItem) {
                return {
                    name: "products",
                    query: { brands: slug },
                };
            }
        }

        if (parentNavItem.itemType === NavItemType.BrandItem) {
            if (itemType === NavItemType.BrandSeriesItem) {
                return {
                    name: "products",
                    query: { brands: parentNavItem.slug, series: slug },
                };
            }
        }
    }

    if ([NavItemType.SinglePageMenuItem, NavItemType.PageItem].includes(itemType)) {
        return { name: "page", params: { slug } };
    }
    if (NavItemType.ProductItem === itemType) {
        return { name: "card", params: { slug } };
    }
    if (NavItemType.CatalogItem === itemType || slug === "products") {
        return { name: "products" };
    }

    if (NavItemType.MenuItem === itemType && slug === "education") {
        return { name: "training" };
    }

    return { name: "page", params: { slug } };
}
