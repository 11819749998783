// import validation from '@/mixins/validation.js'
// import mainInput from '../../atoms/main-input/index.vue'
import { mapActions, mapGetters, mapMutations } from "vuex";
import { maxLength, minLength, required, sameAs } from "vuelidate/lib/validators";
import validation from "@/mixins/validation";

export default {
    name: "restore",
    data() {
        return {
            payload: {
                password: "",
                password_confirmation: "",
                token: this.$route.query.token,
                email: this.$route.query.email,
            },
            validationErrors: {},
        };
    },
    mixins: [validation],
    validations: {
        payload: {
            password: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(120),
            },
            password_confirmation: {
                required,
                minLength: minLength(8),
                sameAsPassword: sameAs("password"),
            },
        },
    },
    watch: {
        "payload.password": function() {
            this.$v.$reset();
        },
        "payload.password_confirmation": function() {
            this.$v.$reset();
        },
    },
    components: {},

    computed: {
        ...mapGetters({
            isLoading: "auth/isLoading",
        }),
        passwordErrors() {
            return this.computeErrors('payload', 'password', {
                maxLength: {message: "validationMax", count: 120 },
                required: "validationRequired",
                minLength: {message: "validationMin", count: 8},
            });
        },
        passwordConfirmationErrors() {
            return this.computeErrors('payload', 'password_confirmation', {
                maxLength: {message: "validationMax", count: 120 },
                required: "validationRequired",
                sameAsPassword: "validationSameAs",
                minLength: {message: "validationMin", count: 8},
            });
        },
    },
    created() {
        document.getElementById("html").classList.add("hide");
    },
    methods: {
        ...mapMutations({
            changeRestorePopup: "popups/CHANGE_RESTORE_POPUP",
        }),
        ...mapActions({
            restorePassword: "auth/RESTORE_PASSWORD",
        }),
        submit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.restorePassword(this.payload).then(() => {
                this.$toasted.success(this.$t("successRestorePassword"));
                this.$router.replace({ query: null }).catch(() => {});
                this.changeRestorePopup(false);
                window.location.reload();
            }).catch((error) => {
                this.$toasted.error(error.data.message);
            });
        },
    },
};
