import { mapActions, mapGetters } from "vuex";
import defaultPrice from "../../../modules/buy-flow/pages/basket/components/default-price/index.vue";
import _ from "lodash";
import { downloadFileFrom } from "@/helpers";

export default {
    name: "default-prices",
    components: {
        defaultPrice,
    },
    data() {
        return {
            width: window.innerWidth
        };
    },
    created() {
        document.getElementById("html").classList.add("hide");
    },
    computed: {
        ...mapGetters({
            productsPdf: "order/productsPdf",
            basket: "basket/basket",
            downloadPdfLoading: "order/downloadPdfLoading",
            user: "profile/user",
            isDistributor: "profile/isDistributor",
            isProfessional: "profile/isProfessional",
        }),
        totalSumClient() {
            return _.sumBy(this.basket, product => (product.select_type.clientPrice || 0) * product.select_count);
        },
    },
    methods: {
        ...mapActions({
            fetchPdf: `order/DOWNLOAD_PDF`,
        }),
        async downloadPdf() {
            const products = _.map(this.basket, product => ({
                id: product.id,
                value: product.select_type.value,
                count: product.select_count,
            }));

            await this.fetchPdf({ products });
            await downloadFileFrom(this.productsPdf.path, "Накладна для пацієнта.pdf");
        },
        updateWidth() {
            this.width = window.innerWidth;
        },
    },
    mounted() {
        window.addEventListener('resize', this.updateWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateWidth);
    },
};
