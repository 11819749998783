interface State {
    videoLink: string | null;
    popupOpen: boolean;
}

const state: State = {
    videoLink: null,
    popupOpen: false
}

const getters = {
    videoLink: (state: State) => state.videoLink,
    popupOpen: (state: State) => state.popupOpen,
}

const actions = {}

const mutations = {
    OPEN_METODIST_POPUP(state: State, videoURL: any) {
        state.videoLink = videoURL;
        state.popupOpen = true;
    },
    CLOSE_METODIST_POPUP(state: State) {
        state.videoLink = null;
        state.popupOpen = false;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
