import { fixBody } from "@/helpers/variables";

interface State {
    fixBody: any;
    showCatalogMenu: any;
    globalLoader: any;
    showFilter: any;
    openMenu: any;
    openSecondMenu: any;
    openFirstMenu: any;
    breadcrumbsList: any;
    propsSecondMenu: any;
    popup: any;
    popupTextShow: any;
}

const state: State = {
    globalLoader: false,
    fixBody: false,
    openMenu: true,
    openSecondMenu: false,
    openFirstMenu: false,
    breadcrumbsList: null,
    propsSecondMenu: null,
    showFilter: true,
    showCatalogMenu: false,
    popup: {
        title: "",
        text: "",
    },
    popupTextShow: false,
};

const getters = {
    fixBody: (state: State) => state.fixBody,
    showCatalogMenu: (state: State) => state.showCatalogMenu,
    globalLoader: (state: State) => state.globalLoader,
    showFilter: (state: State) => state.showFilter,
    openMenu: (state: State) => state.openMenu,
    openSecondMenu: (state: State) => state.openSecondMenu,
    openFirstMenu: (state: State) => state.openFirstMenu,
    breadcrumbsList: (state: State) => state.breadcrumbsList,
    propsSecondMenu: (state: State) => state.propsSecondMenu,
    popup: (state: State) => state.popup,
    popupTextShow: (state: State) => state.popupTextShow,
};

const mutations = {
    SET_CUSTOM_BREADCRUMBS_LIST(state: State, list: any) {
        state.breadcrumbsList = list;
    },
    RESET_CUSTOM_BREADCRUMBS_LIST(state: State, list: any) {
        state.breadcrumbsList = null;
    },
    CHANGE_OPEN_MENU(state: State, status: any) {
        state.openMenu = status;
        if (status) {
            state.openFirstMenu = false;
            state.openSecondMenu = false;
        }
        fixBody(status);
    },
    CHANGE_OPEN_SECOND_MENU(state: State, status: any) {
        state.openSecondMenu = status;
    },
    CHANGE_OPEN_FIRST_MENU(state: State, status: any) {
        state.openFirstMenu = status;
    },
    CHANGE_OPEN_FILTER(state: State, status: any) {
        state.showFilter = status;
    },
    SET_PROPS_SECOND_MENU(state: State, list: any) {
        state.propsSecondMenu = list;
    },
    SHOW_GLOBAL_LOADER(state: State, status: any) {
        state.globalLoader = status;
    },
    FIX_BODY(state: State, param: any) {
        const body = document.querySelector("body");
        if (!body) return;
        if (param) {
            body.classList.add("fix");
        } else {
            body.classList.remove("fix");
        }
        state.fixBody = param;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
