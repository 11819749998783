export default {
    methods: {
        computeErrors(searchIn, field, validators) {
            let error = [];
            let validationField = this.$v[searchIn][field] || {};

            if (!validationField.$dirty) return error;

            for (let key in validators) {
                if (!validationField[key]) {
                    if (typeof validators[key] === 'string') {
                        error.push(this.$t(validators[key]));
                    }
                    else {
                        error.push(this.$t(validators[key].message).replace(":count", validators[key].count));
                    }
                }
            }

            if (this.validationErrors[field]) {
                this.validationErrors[field].forEach(row => {
                    error.push(row);
                });
            }

            return error;
        },
    },
};
